import {Tag,Select,Option,Input,Button,Pagination } from 'element-ui';
import {getList,getConfig,searchList} from '../api/index.js';
export default {
  name:'index',
  components:{
    elTag:Tag,
    elSelect:Select,
    elOption:Option,
    elInput:Input,
    elButton:Button,
    elPagination:Pagination,
  },
  data(){
    this.limit=20;
    return {
      input:'',
      type:'all',
      select:"",
      currentPage:1,
      total:0,
      options:[],
      tags:[],
      list:[]
    }
  },
  async mounted(){
    this.getList();
    this.getConfig();
  },
  methods:{
    async getConfig(){
      const res = await getConfig(); 
      const {data = {}} = res;
      const {index_project_tags = [],index_project_order_by=[]} = data;
      this.tags = index_project_tags;
      this.options = index_project_order_by;
    },
    async getList(){
      const res = await getList({
        limit:this.limit,
        offset:this.currentPage -1
      }); 
      this.list = res.data.results;
      this.total = res.data.total;
    },
    chooseTag(e){
      this.type = e.target.dataset.type;
      this.search();
    },
    chooseOption(){
      this.search();
    },
    changeInput(){
      console.log('input',this.input)
    },
    pageTodetail(id){
      this.$router.push(`/product-detail?id=${id}`);
    },
    pageToAuthor(uid){
      this.$router.push(`user?uid=${uid}`);
    },
    async search(){
      console.log('search')
      const data = {
        limit:this.limit,
        offset:this.currentPage -1,
        keyword:this.input,
        tag:this.type,
        order_by:this.select
      };
      const res = await searchList(data);
      this.list = res.data.results;
      this.total = res.data.total;
    },
    changePage(curPage){
      this.currentPage = curPage;
      this.getList();
    }
  }
}